import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import store from "./../../store";

// Bring in Redux
import { hasRoles } from "../../utils/roles";

// Functions
import isEmpty from "../../validation/is-empty";

import "./BenchmarkingAnonymity.css";

/// props validation | SQ(javascript:S6774)
BenchmarkingAnonymity.propTypes = {
  caller: PropTypes.string,
  thisBenchmark: PropTypes.object,
  onBenchmarkAnonymityToggle: PropTypes.func,
  auth: PropTypes.object,
};
///
export default function BenchmarkingAnonymity(props) {
  const { caller } = props;

  const { thisBenchmark } = props;
  const { onBenchmarkAnonymityToggle } = props;

  const { user } = store.getState().auth;

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  if (typeof onBenchmarkAnonymityToggle !== "function") {
    return <></>;
  }

  // for certain callers, show toggle on thisBenchmark
  const showThisBenchmarkToggle = ["MASTER_BENCHMARKING"].includes(caller);

  const { linkedBenchmarksIds } = thisBenchmark;

  const display = [];

  display.push(
    <ToggleWrapper
      key={thisBenchmark.id}
      name={thisBenchmark.project_name_original || thisBenchmark.project_name}
      showToggle={showThisBenchmarkToggle}
      isAnonymous={thisBenchmark.isAnonymous}
      benchmarkId={thisBenchmark.id}
      comparisonId={thisBenchmark.id}
      linkedBenchmarksIds={linkedBenchmarksIds}
      onBenchmarkAnonymityToggle={onBenchmarkAnonymityToggle}
    />
  );

  linkedBenchmarksIds.forEach((linkedBenchmarkId, i) => {
    const linked_benchmark = thisBenchmark.linked_benchmarks[i];

    if (!isEmpty(linked_benchmark)) {
      display.push(
        <ToggleWrapper
          key={linkedBenchmarkId.benchmarkId}
          name={
            linked_benchmark.project_name_original ||
            linked_benchmark.project_name
          }
          showToggle={true}
          isAnonymous={linkedBenchmarkId.isAnonymous}
          benchmarkId={thisBenchmark.id}
          comparisonId={linkedBenchmarkId.benchmarkId}
          linkedBenchmarksIds={linkedBenchmarksIds}
          onBenchmarkAnonymityToggle={onBenchmarkAnonymityToggle}
        />
      );
    }
  });

  return <div className="benchmarking-table-anonymity">{display}</div>;
}

/// props validation | SQ(javascript:S6774)
ToggleWrapper.propTypes = {
  name: PropTypes.string,
  showToggle: PropTypes.bool,
  isAnonymous: PropTypes.bool,
};
///
function ToggleWrapper(props) {
  const { name, showToggle } = props;
  const { isAnonymous } = props;

  return (
    <div className="toggle-wrapper">
      {showToggle && (
        <div className="toggle-container">
          <Toggle {...props} />
        </div>
      )}
      <div className="toggle-hidden-name">{isAnonymous ? name : ""}</div>
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
Toggle.propTypes = {
  isAnonymous: PropTypes.bool,
  benchmarkId: PropTypes.string,
  comparisonId: PropTypes.string,
  linkedBenchmarksIds: PropTypes.array,
  onBenchmarkAnonymityToggle: PropTypes.func,
};
///
function Toggle(props) {
  const { isAnonymous: setting } = props;
  const { benchmarkId, comparisonId } = props;
  const { linkedBenchmarksIds } = props;
  const { onBenchmarkAnonymityToggle } = props;

  const [state, setState] = useState(setting);

  useEffect(() => {
    setState(setting);
  }, [setting]);

  const tooltip = state ? "Anonymous" : "Identified";

  return (
    <label className="table-switch">
      <input
        type="checkbox"
        checked={!state}
        onChange={() => {
          const isAnonymous = !state;
          setState(isAnonymous);

          // update state within linkedBenchmarksIds
          const linked_benchmark = linkedBenchmarksIds.find(
            (x) => x.benchmarkId === comparisonId
          );
          linked_benchmark && (linked_benchmark.isAnonymous = isAnonymous);

          onBenchmarkAnonymityToggle({
            benchmarkId,
            linkedBenchmarksIds,
            comparisonId,
            isAnonymous,
          });
        }}
      />
      <span className="slider round" title={tooltip} />{" "}
    </label>
  );
}
