import React from "react";
import brand2 from "../../img/ArcadisClarity02.png";

import "./About.css";

export default function About() {
  return (
    <div className="about">
      <img className="landing-brand" width="700" src={brand2} alt="" />

      <div className="about-background">
        <div className="about-title">
          <h1 className="display-4">About</h1>
        </div>
        <div className="about-version">
          <div>
            <div className="about-version-row">
              <div className="about-version-label">
                <b>Version:</b>
              </div>
              <div className="about-version-value">v7.0.3</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
