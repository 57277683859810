import React from "react";
import "./BenchmarkProjectInfoView.css";
import isEmpty from "../../validation/is-empty";
import PropTypes from "prop-types";

import { nameFromEmail, FormatDate } from "../../helpers/common.functions";

BenchmarkProjectInfoView.propTypes = {
  rowData: PropTypes.object,
};
export default function BenchmarkProjectInfoView(props) {
  const { rowData } = props;
  let combinedRows = [];
  if (isEmpty(rowData)) return null;

  combinedRows = prepareProjectInfoTable(rowData);

  return (
    <div className="create-benchmark-container">
      <div className="create-benchmark-form-container">
        <div className="create-project-panel create-project-matrix-panel custom-margin">
          <div>
            <TitledTable rows={combinedRows} />
          </div>
        </div>
      </div>
    </div>
  );
}

TitledTable.propTypes = {
  rows: PropTypes.array,
  heading: PropTypes.string,
};

export function prepareProjectInfoTable(rowData) {
  let combinedRows = [];
  const tableConfigs = {
    columns: {
      col: { visible: true },
      col1: { visible: false },
      col2: { visible: true },
      col3: { visible: true },
    },
    rows: {
      subheading: { visible: true },
    },
    cells: {},
  };
  tableProjectInfo(rowData).forEach((element) => {
    Object.entries(element).forEach(([key, value]) => {
      Object.entries(value).forEach((row) => {
        if (row[1].col1 === "subheading") {
          combinedRows.push(
            <TableSubHeader subHeading={row[1].col3} config={tableConfigs} />
          );
        } else {
          combinedRows.push(
            <TableRow
              key={row[1].col1 + `${combinedRows.length}`}
              itemData={row[1]}
              config={tableConfigs}
            ></TableRow>
          );
        }
      });
    });
  });
  combinedRows = combinedRows.filter((filledrows) => filledrows != null);
  return combinedRows;
}

export function TitledTable(props) {
  const { rows, heading } = props;
  return (
    <div>
      <h5>{heading}</h5>
      {rows}
    </div>
  );
}

TableSubHeader.propTypes = {
  config: PropTypes.object,
  subHeading: PropTypes.string,
};

export function TableSubHeader(props) {
  const { subHeading } = props;
  const { config } = props;
  if (!config.rows.subheading.visible) return null;
  return (
    <div className="pctm-container-horizontal pctm-container-matrix-horizontal">
      <div className="pctm-row pctm-matrix-row pctm-matrix-subheader">
        {subHeading}
      </div>
    </div>
  );
}

TableRow.propTypes = {
  config: PropTypes.object,
  itemData: PropTypes.object,
};

export function TableRow(props) {
  const { itemData } = props;
  const { config } = props;

  const cols = [];

  Object.entries(itemData).forEach(([key, value]) => {
    if (config.columns[key].visible) {
      cols.push(
        <div className={"project-info-tab1-table-cell-text " + key}>
          {value}
        </div>
      );
    }
  });
  return (
    <div className="pctm-container-horizontal pctm-container-matrix-horizontal">
      <div className="project-info-table-row">{cols}</div>
    </div>
  );
}

export function tableProjectInfo(rowData) {
  console.log("rowData", rowData);

  return [
    {
      subHeading1: [{ col1: "subheading", col2: "", col3: "Project Details" }],
      projectDetails: [
        {
          col: "P1.1",
          col1: "project_number",
          col2: "Oracle Project Number",
          col3: rowData.project_number,
        },
        {
          col: "P1.2",
          col1: "project_name",
          col2: "Project Name",
          col3: rowData.project_name,
        },
        {
          col: "P1.3",
          col1: "project_name_short",
          col2: "Project Name (Short - 12 characters max)",
          col3: rowData.project_name_short,
        },
        {
          col: "P1.4",
          col1: "project_stage",
          col2: "Project Stage (where cost details captured)",
          col3: rowData.project_stage,
        },
        {
          col: "P1.5",
          col1: "source",
          col2: "Source Info for Elemental Analysis",
          col3: rowData.analysis_basis,
        },
        {
          col: "P1.6",
          col1: "base_date",
          col2: "Date of Source Info",
          col3: FormatDate(rowData.base_date),
        },
        {
          col: "P1.7",
          col1: "project_manager",
          col2: "Project Cost Manager",
          col3: nameFromEmail(rowData?.contactEmail),
        },
        {
          col: "P1.8",
          col1: "contactEmail",
          col2: "Contact Email Address",
          col3: rowData.contactEmail,
        },
        {
          col: "P1.9",
          col1: "compiled_date",
          col2: "Date Benchmark Costs Data Compiled",
          col3: FormatDate(rowData.compiled_date),
        },
      ],
      subHeading2: [
        { col1: "subheading", col2: "", col3: "Project Categories" },
      ],
      projectCategories: [
        {
          col: "P2.1",
          col1: "sector",
          col2: "Asset Class (Level 1)",
          col3: rowData.sector,
        },
        {
          col: "P2.2",
          col1: "sub_sector",
          col2: "Asset Class (Level 2)",
          col3: rowData.sub_sector,
        },
        {
          col: "P2.4",
          col1: "union_status",
          col2: "Union / Non-Union",
          col3: rowData.union_status,
        },
        {
          col: "P2.5",
          col1: "quality",
          col2: "Quality Level (As Applicable)",
          col3: rowData.quality,
        },
        {
          col: "P2.6",
          col1: "project_type",
          col2: "Project Type",
          col3: rowData.project_type,
        },
        {
          col: "P2.7",
          col1: "currency",
          col2: "Selected Currency (i.e. AUD, GBP, USD etc)",
          col3: rowData.currency,
        },
        {
          col: "P2.8",
          col1: "region",
          col2: "Location (Region)",
          col3: rowData.region,
        },
        {
          col: "P2.9",
          col1: "country",
          col2: "Location (Country)",
          col3: rowData.country,
        },
        {
          col: "P2.10",
          col1: "state_territory_province",
          col2: "Location (State / Territory / Province)",
          col3: rowData.state_territory_province,
        },
        {
          col: "P2.11",
          col1: "city",
          col2: "Location (Town / City / Suburb)",
          col3: rowData.city,
        },
      ],
      subHeading3: [
        { col1: "subheading", col2: "", col3: "Project Procurement" },
      ],
      projectProcurement: [
        {
          col: "P3.1",
          col1: "procurement_method",
          col2: "Procurement Method",
          col3: rowData.procurement_method,
        },
        {
          col: "P3.2",
          col1: "program_duration",
          col2: "Program Duration (to be stated in weeks)",
          col3: rowData.program_duration,
        },
        {
          col: "P3.3",
          col1: "form_of_contract",
          col2: "Form of Contract (if known)",
          col3: rowData.form_of_contract,
        },
      ],
      subHeading4: [{ col1: "subheading", col2: "", col3: "Project Team" }],
      projectTeam: [
        {
          col: "P4.1",
          col1: "client",
          col2: "Client",
          col3: rowData.client,
        },
        {
          col: "P4.2",
          col1: "project_manager",
          col2: "Project Manager",
          col3: rowData.project_manager,
        },
        {
          col: "P4.3",
          col1: "architect_designer",
          col2: "Architect / Designer",
          col3: rowData.architect_designer,
        },
        {
          col: "P4.4",
          col1: "structural_engineer",
          col2: "Structural Engineer",
          col3: rowData.structural_engineer,
        },
        {
          col: "P4.5",
          col1: "mechanical_engineer",
          col2: "Mechanical Engineer",
          col3: rowData.mechanical_engineer,
        },
        {
          col: "P4.6",
          col1: "hydraulic_engineer",
          col2: "Hydraulic Engineer",
          col3: rowData.hydraulic_engineer,
        },
        {
          col: "P4.7",
          col1: "electrical_engineer",
          col2: "Electrical Engineer",
          col3: rowData.electrical_engineer,
        },
        {
          col: "P4.8",
          col1: "mainContractor",
          col2: "Main Contractor",
          col3: rowData.main_contractor,
        },
      ],
    },
  ];
}
