import PropTypes from "prop-types";
import React from "react";
import { useParams } from "react-router-dom";
import { renderToString } from "react-dom/server";
import Spinner from "../common/Spinner";

import CostReport from "./PostContractCostReport.components";
import ProgressClaim from "./PostContractProgressClaim.components";
import PostContractComponent from "./PostContractComponent";
import Commentary from "../estimate-commentary/Commentary";
import CashflowContainer from "../post-contract-cashflow/CashflowContainer";
import { pc2APIs } from "../../api/api-endpoints/PostContract2.0Endpoints";

import "./PostContractVariationsStatusTable.css";
import "./PostContractSection.css";

PostContractSection.propTypes = {
  project: PropTypes.object,
  user: PropTypes.object,
  selectedReport: PropTypes.object,
  postContract: PropTypes.object,

  dashboardSettings: PropTypes.object,
  showCRPanel: PropTypes.object,
  showPCPanel: PropTypes.object,

  setShowCRPanel: PropTypes.func,
  setShowPCPanel: PropTypes.func,
};

export default function PostContractSection(props) {
  // Project
  const { project } = props;
  const { user } = props;

  // Post Contract
  const { selectedReport } = props;
  const { postContract } = props;

  // Dashboard
  const { dashboardSettings } = props;
  const { showCRPanel, setShowCRPanel } = props;
  const { showPCPanel, setShowPCPanel } = props;

  const params = useParams();
  const { pagename } = params;

  if (postContract.loading) {
    return (
      <div className="post-contract-background-loading">
        <Spinner />
      </div>
    );
  }

  if (pagename === "Cost Report") {
    return (
      <div className="post-contract-background">
        <CostReport
          project={project}
          selectedReport={selectedReport}
          postContract={postContract}
          dashboardSettings={dashboardSettings}
          showCRPanel={showCRPanel}
          setShowCRPanel={setShowCRPanel}
        />
      </div>
    );
  } else if (pagename === "Progress Claim") {
    return (
      <div className="post-contract-background">
        <ProgressClaim
          project={project}
          selectedReport={selectedReport}
          postContract={postContract}
          dashboardSettings={dashboardSettings}
          showPCPanel={showPCPanel}
          setShowPCPanel={setShowPCPanel}
        />
      </div>
    );
  } else if (pagename === "Commentary") {
    return (
      <Commentary
        project={project}
        CPs={postContract}
        CP={postContract}
        Comments={postContract.data[0]?.Advice.Commentary}
        URLs={{
          endpoint: pc2APIs,
          updateStateFunction: async () => {
            const URL = window.location.href;
            const sheetName = URL.substring(URL.lastIndexOf("/") + 1);
            postContract.set(
              await postContract.get(
                selectedReport.ProjectId,
                selectedReport.Id,
                sheetName
              )
            );
          },
        }}
        CostId={selectedReport.Id}
        Module={"PostContract"}
        user={user}
      />
    );
  } else if (pagename === "Cashflow") {
    return (
      <CashflowContainer
        project={project}
        postContract={postContract}
        selectedReport={selectedReport}
      />
    );
  } else {
    return (
      <div className="post-contract-background">
        <div className="pctc-container">
          <TableDashboard
            selectedReport={selectedReport}
            postContract={postContract}
          />
          <PostContractComponent
            project={project}
            selectedReport={selectedReport}
            sectionType={"Registers"}
            postContract={postContract}
            componentName={pagename}
            componentType={"TableComponents"}
            isTable={true}
            isRegister={true}
            pageSize={15}
          />
        </div>
      </div>
    );
  }
}

TableDashboard.propTypes = {
  postContract: PropTypes.object,
  selectedReport: PropTypes.object,
};

function TableDashboard(props) {
  const { postContract } = props;
  const { selectedReport } = props;

  // The list of all possible visualisations
  const visuals = [
    // Variations
    <div className="pc-ccv-status" key={"Variation Status Table"}>
      <PostContractComponent
        postContract={postContract}
        selectedReport={selectedReport}
        componentType={"TableComponents"}
        sectionType={"Registers"}
        componentName={"Variation Status Table"}
        key={"Variation Status Table"}
        dashboardId={"Variation Status Table"}
      />
    </div>,
    <PostContractComponent
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"DonutCharts"}
      sectionType={"Registers"}
      componentName={"Change Type Summary Chart"}
      key={"Change Type Summary Chart"}
      dashboardId={"Change Type Summary Chart"}
    />,

    // Provisional Sums
    <PostContractComponent
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"CostBudgetCharts"}
      sectionType={"Registers"}
      componentName={"Provisional Sums Summary"}
      key={"Provisional Sums Summary"}
      dashboardId={"Provisional Sums Summary"}
    />,
    <PostContractComponent
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Registers"}
      componentName={"Provisional Sums Status Table"}
      key={"Provisional Sums Status Table"}
      dashboardId={"Provisional Sums Status Table"}
    />,
    <PostContractComponent
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"DonutCharts"}
      sectionType={"Registers"}
      componentName={"Provisional Sums Summary Chart"}
      key={"Provisional Sums Summary Chart"}
      dashboardId={"Provisional Sums Summary Chart"}
    />,

    // Client Direct Costs
    <PostContractComponent
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"CostBudgetCharts"}
      sectionType={"Registers"}
      componentName={"Client Direct Costs Summary Chart"}
      key={"Client Direct Costs Summary Chart"}
      dashboardId={"Client Direct Costs Summary Chart"}
    />,

    // Risk Register
    <PostContractComponent
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"DonutCharts"}
      sectionType={"Registers"}
      componentName={"Risk Types Chart"}
      key={"Risk Types Chart"}
      dashboardId={"Risk Types Chart"}
    />,
  ];
  const display = [];

  visuals.forEach((Component) => {
    // Do not render empty components
    let stringElement = renderToString(Component);
    if (stringElement === "") {
      return null;
    }

    display.push(
      <div key={Component.dashboardId} className="pctc-visual">
        {Component}
      </div>
    );
  });

  if (display.length > 0) {
    return <div className="pctc">{display}</div>;
  } else {
    return null;
  }
}
