import PropTypes from "prop-types";
import { translate } from "../../utils/translation";

TableHeadingsCell.propTypes = {
  cell: PropTypes.object,
};

export function TableHeadingsCell(props) {
  const { cell } = props;
  const { type } = cell;

  if (type === "NUMBER") {
    return <NumberHeading {...props} />;
  }
  if (type === "DESCRIPTION") {
    return <DescriptionHeading {...props} />;
  }
  if (type === "STATUS") {
    return <NormalHeading {...props} />;
  }
  if (type === "CURRENCY") {
    return <NormalHeading {...props} />;
  }
  if (type === "QUANTITY") {
    return <NormalHeading {...props} />;
  }
  if (type === "PERCENT") {
    return <PercentHeading {...props} />;
  }
  if (type === "DATE") {
    return <PercentHeading {...props} />;
  }
  if (type === "TEXT") {
    return <NormalHeading {...props} />;
  }
  if (type === "REASONS") {
    return <IconHeading {...props} />;
  }
  if (type === "COMMENTS") {
    return <IconHeading {...props} />;
  }
  if (type === "LINK") {
    return <IconHeading {...props} />;
  }
  if (type === "CRITICAL") {
    return <IconHeading {...props} />;
  }

  if (type === "CHECK") {
    return <NormalHeading {...props} />;
  }

  if (type === "IMAGE") {
    return <NormalHeading {...props} />;
  }
}

NumberHeading.propTypes = {
  value: PropTypes.string,
  width: PropTypes.number,
};

function NumberHeading(props) {
  const { value } = props;
  const { width } = props;

  return (
    <div className="pct-heading-number" style={{ width: width }}>
      <b>{translate(value)}</b>
    </div>
  );
}

DescriptionHeading.propTypes = {
  value: PropTypes.string,
  width: PropTypes.number,
};

function DescriptionHeading(props) {
  const { value } = props;
  const { width } = props;

  return (
    <div className="pct-heading-description" style={{ width: width }}>
      <b>{translate(value)}</b>
    </div>
  );
}

NormalHeading.propTypes = {
  value: PropTypes.string,
  width: PropTypes.number,
};

function NormalHeading(props) {
  const { value } = props;
  const { width } = props;

  return (
    <div className="pct-heading" style={{ width: width }}>
      <b>{translate(value)}</b>
    </div>
  );
}

IconHeading.propTypes = {
  value: PropTypes.string,
  width: PropTypes.number,
};

function IconHeading(props) {
  const { value } = props;
  const { width } = props;

  return (
    <div className="pct-heading-icon" style={{ width: width }}>
      <b>{translate(value)}</b>
    </div>
  );
}

PercentHeading.propTypes = {
  value: PropTypes.string,
  width: PropTypes.number,
};

function PercentHeading(props) {
  const { value } = props;
  const { width } = props;

  return (
    <div className="pct-heading-percent" style={{ width: width }}>
      <b>{translate(value)}</b>
    </div>
  );
}
