import React, { useState } from "react";

import CreateBenchmarkFormDetails from "./CreateBenchmarkFormDetails";
import { costPlanningBenchmarkCreate } from "../../api/cost-planning/CostPlanningBenchmarkAPI";
import "./CreateBenchmarkForm.css";
import CreateBenchmarkFormRows from "./CreateBenchmarkFormRows";
import CreateBenchmarkFormMetrics from "./CreateBenchmarkFormMetrics";

import { useCbs, useCbsCodes } from "../../api/cbs/CbsAPI";

export default function CreateBenchmarkForm(props) {
  // Fields
  const { benchmarkName } = props;
  const { benchmarkClient } = props;
  const { benchmarkStandard } = props;
  const { benchmarkDate } = props;
  const { benchmarkArea } = props;
  const { benchmarkConCost } = props;
  const { benchmarkQuality } = props;
  const { benchmarkSector } = props;
  const { benchmarkSubsector } = props;
  const { benchmarkProcurement } = props;
  const { benchmarkProjectType } = props;
  const { benchmarkUnit } = props;
  const { benchmarkSource } = props;

  // Location Fields
  const { benchmarkRegion } = props;
  const { benchmarkCountry } = props;
  const { benchmarkCity } = props;
  const { benchmarkTPICity } = props;

  // Data not in fields
  const { benchmarkCurrency } = props;
  const { benchmarkMeasurementSystem } = props;
  const { benchmarkContactEmail } = props;

  // Benchmark Rows
  const { benchmarkRows } = props;

  // Benchmark Metrics
  const { benchmarkMetrics } = props;

  // General Data
  const { url } = props;
  const { mappingFunction } = props;
  const { exclusiveProjectId } = props;

  // Enabled Forms
  const { detailsFormIsEnabled } = props;
  const { rowsFormIsEnabled } = props;
  const { metricsFormIsEnabled } = props;
  const { setShow } = props;

  // Form State - Project Details
  const [projectName, setProjectName] = useState(benchmarkName);
  const [client, setClient] = useState(benchmarkClient);
  const [baseDate, setBaseDate] = useState(benchmarkDate);
  const [area, setArea] = useState(benchmarkArea);
  const [areaDescription, setAreaDescription] = useState("GFA");
  const [unitOfMeasure, setUnitOfMeasure] = useState(benchmarkUnit);
  const [conCost, setConCost] = useState(benchmarkConCost);

  // Form State - Project Categories
  const [quality, setQuality] = useState(benchmarkQuality);
  const [sector, setSector] = useState(benchmarkSector);
  const [subsector, setSubsector] = useState(benchmarkSubsector);
  const [procurement, setProcurement] = useState(benchmarkProcurement);
  const [projectType, setProjectType] = useState(benchmarkProjectType);
  const [standard, setStandard] = useState(benchmarkStandard);

  // Form State - Location Details
  const [region, setRegion] = useState(benchmarkRegion);
  const [country, setCountry] = useState(benchmarkCountry);
  const [city, setCity] = useState(benchmarkCity);
  const [TPICity, setTPICity] = useState(benchmarkTPICity);

  // Form State - Rows
  const [rows, setRows] = useState([]);

  // Form State - Metrics
  const [metrics, setMetrics] = useState([]);

  // Errors
  const [errors, setErrors] = useState({});

  // Cost Breakdown Structure for Elemental Analysis ("Rows")
  const isNotPublished = false;
  const { cbsCodes } = useCbsCodes(isNotPublished);
  const { cbs } = useCbs(standard);

  const data = {
    payload: {
      // IDs
      BenchmarkId: null, // optional

      // Benchmark Details
      ProjectName: projectName,
      ProjectNameShort: projectName,
      Client: client,
      FileName: "Created from Quick Benchmark",
      ContactEmail: benchmarkContactEmail,
      AnalysisBasis: "Quick Benchmark",
      Source: benchmarkSource,
      ExclusiveProjectId: exclusiveProjectId,

      // Benchmark Dates
      BaseDate: new Date(baseDate).toISOString(),
      CompiledDate: new Date(Date.now()).toISOString(),

      // Benchmark Categories
      Quality: quality,
      Sector: sector,
      SubSector: subsector,
      ProcurementMethod: procurement,
      ProjectType: projectType,
      Standard: standard,
      Currency: benchmarkCurrency,

      // Benchmark Location
      Region: region,
      Country: country,
      City: city,
      TpiCity: TPICity,

      // Benchmark Measurement
      MeasurementSystem: benchmarkMeasurementSystem,
      LocalRegionArea: area,
      LocalRegionAreaDescription: areaDescription,

      // Benchmark Costs
      ConstructionCost: conCost,

      // Form State - Rows
      BenchmarkElements: rows.map((r) => {
        return {
          Code: r.code,
          Description: r.description,
          Quantity: r.quantity,
          Unit: r.unit,
          LocalRegionRate: r.local_region_rate,
          Total: r.total,
        };
      }),
      // Form State - Metrics
      BenchmarkMetrics: metrics.map((m) => {
        return {
          Code: m.code,
          Metric: m.metric,
          Quantity: m.quantity,
          Unit: m.unit,
        };
      }),
    },
    // Error Function
    setErrors,
    // Endpoint
    url,
    // Map the Quick Benchmark to the Estimate (if applicable)
    mappingFunction,
    setShow,
  };

  return (
    <div className="create-benchmark-container">
      <div className="create-benchmark-form-container">
        <div className="create-benchmark-form-container-column-details">
          <CreateBenchmarkFormDetails
            formIsEnabled={detailsFormIsEnabled}
            projectName={projectName}
            setProjectName={setProjectName}
            client={client}
            setClient={setClient}
            conCost={conCost}
            setConCost={setConCost}
            baseDate={baseDate}
            setBaseDate={setBaseDate}
            area={area}
            setArea={setArea}
            areaDescription={areaDescription}
            setAreaDescription={setAreaDescription}
            unitOfMeasure={unitOfMeasure}
            setUnitOfMeasure={setUnitOfMeasure}
            quality={quality}
            setQuality={setQuality}
            sector={sector}
            setSector={setSector}
            subsector={subsector}
            setSubsector={setSubsector}
            procurement={procurement}
            setProcurement={setProcurement}
            projectType={projectType}
            setProjectType={setProjectType}
            standard={standard}
            setStandard={setStandard}
            cbsCodes={cbsCodes}
            region={region}
            setRegion={setRegion}
            country={country}
            setCountry={setCountry}
            city={city}
            setCity={setCity}
            TPICity={TPICity}
            setTPICity={setTPICity}
            errors={errors}
          />
        </div>
        <CreateBenchmarkFormRows
          formIsEnabled={rowsFormIsEnabled}
          rows={rows}
          setRows={setRows}
          standard={standard}
          setStandard={setStandard}
          benchmarkRows={benchmarkRows}
          area={area}
          unitOfMeasure={unitOfMeasure}
          cbs={cbs}
        />
        <CreateBenchmarkFormMetrics
          formIsEnabled={metricsFormIsEnabled}
          metrics={metrics}
          setMetrics={setMetrics}
          benchmarkMetrics={benchmarkMetrics}
        />
      </div>
      <Save data={data} error={errors.saving} />
    </div>
  );
}

function Save(props) {
  const { data, error } = props;
  return (
    <div>
      <div className="general-row-container-double">
        <div className="feedback-success">
          <b>{error}</b>
        </div>
      </div>
      <div className="general-row-container-double">
        <button
          id={"create-benchmark-form-button"}
          className="general-upload-button"
          onClick={() => {
            costPlanningBenchmarkCreate(data);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
}
