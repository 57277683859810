import { comma } from "../../helpers/common.functions";
import { tranStr } from "../../utils/translation";

export default function Config() {
  return [
    {
      ComponentName: "Headroom to Budget Table",
      DESCRIPTION: 200,
      CURRENCY: 100,
      NUMBER: 100,
    },
    {
      ComponentName: "Budget Summary Table",
      DESCRIPTION: 140,
      CURRENCY: 90,
      NUMBER: 90,
    },
    {
      ComponentName: "Forecast Cost Summary Table",
      DESCRIPTION: 230,
      CURRENCY: 90,
      NUMBER: 90,
    },
    {
      ComponentName: "Cost Report Table",
      DESCRIPTION: 300,
      CURRENCY: 100,
      TEXT: 200,
    },
    {
      ComponentName: "Variation Status Table",
      DESCRIPTION: 170,
      CURRENCY: 165,
      QUANTITY: 165,
      NUMBER: 165,
    },
    {
      ComponentName: "Provisional Sums Status Table",
      DESCRIPTION: 170,
      CURRENCY: 165,
      QUANTITY: 165,
      NUMBER: 165,
    },
    {
      ComponentName: "Cost Report Comparison Table",
      DESCRIPTION: 150,
      CURRENCY: 175,
      NUMBER: 175,
    },
    {
      ComponentName: "Change Type Summary Table",
      DESCRIPTION: 170,
      CURRENCY: 165,
      QUANTITY: 165,
      NUMBER: 165,
    },
    {
      ComponentName: "Progress Payment Recommendation Table",
      DESCRIPTION: 250,
      CURRENCY: 250,
      NUMBER: 250,
    },
    {
      ComponentName: "Retention Analysis Table",
      DESCRIPTION: 250,
      CURRENCY: 250,
      NUMBER: 250,
    },
    {
      ComponentName: "Contract Statement Table",
      DESCRIPTION: 250,
      CURRENCY: 250,
      NUMBER: 250,
    },
    {
      ComponentName: "Progress Claim Reconciliation Table",
      DESCRIPTION: 250,
      CURRENCY: 250,
      NUMBER: 250,
    },
    {
      ComponentName: "Critical Con Costs Variations Table",
      DESCRIPTION: 250,
      TEXT: 100,
      CURRENCY: 150,
    },
    {
      ComponentName: "Critical Prov Sums Table",
      DESCRIPTION: 250,
      TEXT: 100,
      CURRENCY: 150,
    },
    {
      ComponentName: "Critical Risk Register Table",
      DESCRIPTION: 250,
      TEXT: 100,
      CURRENCY: 150,
    },
    {
      ComponentName: "Risk Types Table",
      DESCRIPTION: 150,
      CURRENCY: 150,
    },
    {
      ComponentName: "Construction Costs",
      DESCRIPTION: 200,
      TEXT: 100,
      STATUS: 100,
      DATE: 100,
      CURRENCY: 100,
      NUMBER: 50,
      PERCENT: 50,
      LINK: 200,
      REASONS: 200,
      COMMENTS: 200,
      CRITICAL: 50,
    },
    {
      ComponentName: "Construction Costs Variations",
      DESCRIPTION: 200,
      TEXT: 100,
      STATUS: 100,
      DATE: 100,
      CURRENCY: 100,
      NUMBER: 50,
      PERCENT: 50,
      LINK: 200,
      REASONS: 200,
      COMMENTS: 200,
      CRITICAL: 50,
    },
    {
      ComponentName: "Client Directs",
      DESCRIPTION: 200,
      TEXT: 100,
      STATUS: 100,
      DATE: 100,
      CURRENCY: 100,
      NUMBER: 50,
      PERCENT: 50,
      LINK: 200,
      REASONS: 200,
      COMMENTS: 200,
      CRITICAL: 50,
    },
    {
      ComponentName: "Unfixed Materials",
      DESCRIPTION: 200,
      TEXT: 100,
      STATUS: 100,
      DATE: 100,
      CURRENCY: 100,
      NUMBER: 50,
      PERCENT: 50,
      LINK: 200,
      REASONS: 200,
      COMMENTS: 200,
      CRITICAL: 50,
    },
    {
      ComponentName: "Provisional Qtys",
      DESCRIPTION: 200,
      TEXT: 100,
      STATUS: 100,
      DATE: 100,
      CURRENCY: 100,
      NUMBER: 50,
      PERCENT: 50,
      LINK: 200,
      REASONS: 200,
      COMMENTS: 200,
      CRITICAL: 50,
    },
    {
      ComponentName: "Provisional Sums",
      DESCRIPTION: 200,
      TEXT: 100,
      STATUS: 100,
      DATE: 100,
      CURRENCY: 100,
      NUMBER: 50,
      PERCENT: 50,
      LINK: 200,
      REASONS: 200,
      COMMENTS: 200,
      CRITICAL: 50,
    },
    {
      ComponentName: "Provisional Delays",
      DESCRIPTION: 200,
      TEXT: 100,
      STATUS: 100,
      DATE: 100,
      CURRENCY: 100,
      NUMBER: 50,
      PERCENT: 50,
      LINK: 200,
      REASONS: 200,
      COMMENTS: 200,
      CRITICAL: 50,
    },
    {
      ComponentName: "EOTs",
      DESCRIPTION: 200,
      TEXT: 100,
      STATUS: 100,
      DATE: 100,
      CURRENCY: 100,
      NUMBER: 50,
      PERCENT: 50,
      LINK: 200,
      REASONS: 200,
      COMMENTS: 200,
      CRITICAL: 50,
    },
    {
      ComponentName: "Enabling Works",
      DESCRIPTION: 200,
      TEXT: 100,
      STATUS: 100,
      DATE: 100,
      CURRENCY: 100,
      NUMBER: 50,
      PERCENT: 50,
      LINK: 200,
      REASONS: 200,
      COMMENTS: 200,
      CRITICAL: 50,
    },
    {
      ComponentName: "Enabling Works Variations",
      DESCRIPTION: 200,
      TEXT: 100,
      STATUS: 100,
      DATE: 100,
      CURRENCY: 100,
      NUMBER: 50,
      PERCENT: 50,
      LINK: 200,
      REASONS: 200,
      COMMENTS: 200,
      CRITICAL: 50,
    },
    {
      ComponentName: "PT Consultants",
      DESCRIPTION: 200,
      TEXT: 100,
      STATUS: 100,
      DATE: 100,
      CURRENCY: 100,
      NUMBER: 50,
      PERCENT: 50,
      LINK: 200,
      REASONS: 200,
      COMMENTS: 200,
      CRITICAL: 50,
    },
    {
      ComponentName: "PT Consultants Variations",
      DESCRIPTION: 200,
      TEXT: 100,
      STATUS: 100,
      DATE: 100,
      CURRENCY: 100,
      NUMBER: 50,
      PERCENT: 50,
      LINK: 200,
      REASONS: 200,
      COMMENTS: 200,
      CRITICAL: 50,
    },
    {
      ComponentName: "Client Directs",
      DESCRIPTION: 200,
      TEXT: 100,
      STATUS: 100,
      DATE: 100,
      CURRENCY: 100,
      NUMBER: 50,
      PERCENT: 50,
      LINK: 200,
      REASONS: 200,
      COMMENTS: 200,
      CRITICAL: 50,
    },
    {
      ComponentName: "Client Direct Variations",
      DESCRIPTION: 200,
      TEXT: 100,
      STATUS: 100,
      DATE: 100,
      CURRENCY: 100,
      NUMBER: 50,
      PERCENT: 50,
      LINK: 200,
      REASONS: 200,
      COMMENTS: 200,
      CRITICAL: 50,
    },
    {
      ComponentName: "Contingency",
      DESCRIPTION: 200,
      TEXT: 100,
      STATUS: 100,
      DATE: 100,
      CURRENCY: 100,
      NUMBER: 50,
      PERCENT: 50,
      LINK: 200,
      REASONS: 200,
      COMMENTS: 200,
      CRITICAL: 50,
    },
    {
      ComponentName: "Risk Register",
      DESCRIPTION: 200,
      TEXT: 100,
      STATUS: 100,
      DATE: 100,
      CURRENCY: 100,
      NUMBER: 50,
      PERCENT: 50,
      LINK: 200,
      REASONS: 200,
      COMMENTS: 200,
      CRITICAL: 50,
    },
    {
      ComponentName: "Balance of Contingency Chart",
      OPTIONS: () => {
        return {
          animation: {
            duration: 3000,
          },
          plugins: { datalabels: { display: false } },
          maintainAspectRatio: false,
          tooltips: {
            enabled: true,
            callbacks: {
              label: function (tooltipItem, chart) {
                return comma(Math.round(tooltipItem.xLabel));
              },
            },
          },

          scales: {
            xAxes: [
              {
                position: "left",
                stacked: true,
                ticks: {
                  callback: function (tick, index, values) {
                    return comma(Math.round(tick));
                  },
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },

          legend: {
            display: true,
            position: "bottom",
            fullWidth: true,
            reverse: false,
          },
        };
      },
    },
    {
      ComponentName: "Cost Report Comparison Chart",
      OPTIONS: () => {
        return {
          animation: {
            duration: 3000,
          },
          plugins: { datalabels: { display: false } },
          tooltips: {
            enabled: true,
            callbacks: {
              label: function (tooltipItem, chart) {
                return (
                  comma(tooltipItem.yLabel) +
                  " (" +
                  chart.datasets[tooltipItem.datasetIndex].label +
                  ")"
                );
              },
            },
          },
          layout: {
            padding: {
              left: 10,
              right: 10,
              top: 15,
              bottom: 15,
            },
          },
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                position: "left",
                stacked: true,
                ticks: {
                  callback: function (value, index, values) {
                    return comma(Math.round(value));
                  },
                },
              },
            ],
          },

          legend: {
            display: true,
            position: "bottom",
            fullWidth: true,
            reverse: false,
          },
        };
      },
    },
    {
      ComponentName: "Cost Report Trends Chart",
      YaxisIdMapping: {
        "Forecast Total": "forecastTotal",
        "Adjusted Budget": "forecastTotal",
        "Balance of Contingency": "contingency",
      },
      OPTIONS: () => {
        return {
          animation: {
            duration: 0,
          },
          maintainAspectRatio: false,
          plugins: { datalabels: { display: false } },

          tooltips: {
            enabled: true,
            callbacks: {
              label: function (tooltipItem) {
                return comma(Math.round(tooltipItem.yLabel));
              },
            },
          },

          scales: {
            xAxes: [
              {
                stacked: false,
              },
            ],
            yAxes: [
              {
                id: "forecastTotal",
                scaleLabel: {
                  display: true,
                  labelString: tranStr("Budget & Forecast Cost"),
                },
                position: "left",
                stacked: false,
                ticks: {
                  callback: function (value, index, values) {
                    return comma(Math.round(value));
                  },
                },
              },
              {
                id: "contingency",
                scaleLabel: {
                  display: true,
                  labelString: tranStr("Remaining Contingency"),
                },
                position: "right",
                stacked: false,
                ticks: {
                  callback: function (value, index, values) {
                    return comma(Math.round(value));
                  },
                },
              },
            ],
          },
          legend: {
            display: true,
            position: "bottom",
            fullWidth: false,
            reverse: false,
          },
        };
      },
    },
  ];
}
